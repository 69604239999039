import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"
import { ThemeProvider } from "styled-components"
import { theme } from "../theme"
import { BrinkContext } from "../components/context/BrinkContext"
import queryString from "query-string"
import { useLocation } from "@reach/router"
import Loader from "../components/ui/Loader"

const Router = ({ pageContext }) => {
  const location = useLocation()
  const { cart, makeDetailsCall, closeCart } =
    useContext(BrinkContext)

  const useMountEffect = (fun) =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fun, [])


  const handleResult = (order, billingAddress, resultCode) => {
    if (
      resultCode === "Cancelled" ||
      resultCode === "Refused" ||
      resultCode === "Error"
    ) {
      navigate("/checkout/", {
        state: {
          error: resultCode === "Cancelled" ? "cancelled" : "payment-failure"
        }
      })
    } else if (resultCode.toLowerCase() === "authorised") {
      navigate("/success/", {
        state: { order, cart, billingAddress }
      })
      closeCart()
    } else {
      navigate("/")
    }
  }

  useMountEffect(() => {
    const handleDetailsCall = async (orderId, payload, redirectResult) => {
      const details = payload
        ? { payload: payload }
        : { redirectResult: redirectResult }
      const { order, person, paymentResult } = await makeDetailsCall(
        orderId,
        details
      )
      const billingAddress = {
        ...person,
        address: person.streetAddress,
        firstName: person.givenName,
        lastName: person.familyName
      }
      handleResult(order, billingAddress, paymentResult.resultCode)
    }
    const { orderId, payload, redirectResult } = queryString.parse(
      location.search
    )
    if (orderId) {
      handleDetailsCall(orderId, payload, redirectResult)
    } else {
      navigate("/")
    }
  })

  return (
    <ThemeProvider theme={theme(pageContext.sanityTheme)}>
      <Loader isLoading={true} />
    </ThemeProvider>
  )
}

export default Router
